body {
  margin: 0;
  font-family: -apple-system, "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dx-widget input,
.dx-widget textarea {
  font-family: -apple-system, "Inter", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blurring.dimmed.dimmable> :not(.dimmer):not(.dx-overlay-wrapper):not(.dx-overlay-content):not(.dx-overlay) {
  -webkit-filter: blur(30px) grayscale(0.1);
  filter: blur(30px) grayscale(0.1) !important;
}

.blurring.dimmed.dimmable> :not(.dimmer) {
  filter: unset !important;
}

.dimmed.dimmable>.ui.animating.dimmer,
.dimmed.dimmable>.ui.visible.dimmer,
.ui.active.dimmer {
  /* opacity: 0.9!important; */

}



.dx-datagrid .dx-row>td {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  font-size: 13px;
  line-height: 16px;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  line-height: 16px;
  font-weight: 700 !important;
  font-size: 12px;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
  /* background: linear-gradient(to bottom, #4fc5fa 0%, #328af7 100%); */
  background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
  color: white !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines)>td {
  border-top: 1px solid #f5f5f5 !important;
}

.dx-header-row>td:first-of-type {
  border-right: 1px solid #e0e0e0;
}

.dx-header-row>td {
  border-right: 1px solid #e0e0e0 !important;
}

.dx-datagrid .dx-header-filter:before {
  font-size: 10px;
}

.dx-datagrid .dx-header-filter,
.dx-datagrid .dx-sort-down,
.dx-datagrid .dx-sort-up,
.dx-datagrid-container .dx-header-filter,
.dx-datagrid-container .dx-sort-down,
.dx-datagrid-container .dx-sort-up {
  line-height: 10px !important;
}

.dx-datagrid-filter-panel {
  padding: 6px 10px !important;
}

.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
  color: rgba(0, 0, 0, .54) !important;
  font-weight: 500;
  ;
}

.dx-datagrid-filter-panel .dx-icon-filter {
  color: rgba(0, 0, 0, .54) !important;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 20px !important;
  line-height: 20px !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell {
  height: 20px !important;
  line-height: 0px !important;
}

.dx-datagrid .dx-editor-with-menu,
.dx-datagrid-container .dx-editor-with-menu {
  height: 20px !important;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 0px !important;
}

/* .dx-datagrid-table-fixed{
  background-color: #FAFAFA!important;
} */



.header-title {
  padding-left: 20px !important;
}

.side-nav-outer-toolbar {
  background-color: #FFF;
}

.dx-widget {
  font-family: -apple-system, "Inter", sans-serif !important;
}

.brand-header {
  background-color: #0078D4 !important;
  color: #fff !important;
}

.brand-header>.dx-toolbar-items-container {
  height: 48px !important;
}

.user-info .user-name {
  color: #fff !important;
}

.header-component .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
  color: #fff !important;
}

.system-other-list {
  width: 400px !important;
  max-width: 400px !important;
}

.padding-1rem {
  padding: 1rem;
}

.width-400 {
  width: 400px;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.system-other {
  text-align: center;
  border-radius: 10px;
  color: black !important;
}

.system-other>a {
  color: black !important;
}

.system-other:hover {
  background-color: #f2f2f2;
}

.system-other-img {
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
}

.system-other-name {
  margin-top: 0.5rem;
}

.text-align-center {
  text-align: center;
}

.width-100-p {
  width: 100%;
}

.width-50-p {
  width: 40%;
}

.app-name {
  font-size: 20px;
  font-weight: 400;
  color: #333;
  white-space: pre-line;
  pointer-events: all;
}

.homescreen {
  overflow: hidden;
}

.p-fluid {
  width: 100%;
}

.p-white-color {
  color: white !important;
}

.bg-grey {
  background-color: hsla(0, 0%, 74.9%, .15);
}

.bg-background {
  /* background-color: #FAFAFA !important; */
  background-color: #FFF !important;
  padding-left: 15px;
  padding-right: 10px;
}

.padding-2rem {
  padding: 2rem;
}

.padding-top-2rem {
  padding-top: 2rem
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.hide {
  display: none;
}

.dx-checkbox-checked .dx-checkbox-icon {
  color: #fff;
  background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
  border: none;
  font: 14px/14px DXIcons;
  text-align: center;
}

.padding-top-25 {
  padding-top: 25px;
}

.ui.modal>.actions {
  padding: 10px !important;
}

.ui.modal>.actions button {
  margin-right: 10px;
}

.ui.modal>.header {
  font-family: unset !important;
  padding: 8px !important;
  padding-left: 1.5rem !important;
}

.ui.modal>.header>p {
  font-weight: 500 !important;
  font-size: 15px !important;
}

.ui.modal>.content:not(.no-padding) {
  padding-bottom: 40px !important;
}

.dx-editor-outlined {
  border-radius: 8px !important;
  background-color: rgba(0, 0, 0, .04) !important;
  border: none !important;
  box-shadow: none !important;
}

.dx-editor-outlined.dx-state-focused {
  box-shadow: inset 0 0 0 1px #03a9f4 !important;

}

.text-mute {
  color: rgba(0, 0, 0, .5);
}

.ui.inline.loader.active,
.ui.inline.loader.visible {
  margin-left: 5px;
}

.margin-left-5 {
  margin-left: 5px;
}

a {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: unset !important;
}

.loading-login {
  width: 100px;
  height: 100px;
  margin-top: 45vh;
  margin-left: auto;
  margin-right: auto;
}

.dx-field-label-full {
  font-size: 13px;
  cursor: default;
  align-self: center;
  padding-right: 12px;
  color: black !important;
  float: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dx-field-label {
  color: black !important;
}

.dx-texteditor-input {
  color: black !important;
  border-radius: 10px !important;
}

.table-noborder-left>.dx-datagrid>.dx-datagrid-rowsview {
  /* border-left: 0px !important; */
}

.table-noborder-left>.dx-datagrid>.dx-datagrid-headers {
  /* border-left: 0px !important; */
}

.table-noborder-left>.dx-datagrid>.dx-datagrid-filter-panel {
  /* border-left: 0px !important; */
}

.table-noborder-left .dx-datagrid-filter-panel {
  /* border-bottom: 0px !important; */
}

.body-container .dx-drawer-content {
  background-color: #FFF;
}

.dx-rtl .dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input,
.dx-rtl .dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input,
.dx-rtl.dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input,
.dx-rtl.dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input,
.dx-texteditor.dx-editor-filled .dx-placeholder:before,
.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-placeholder:before,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 8px !important;
}

.dx-field-label-100 {
  font-size: 12px;
  cursor: default;
  align-self: center;
  padding-right: 12px;
  color: black !important;
  width: 100% !important;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dx-datagrid .dx-scrollable-content {
  background-color: #FAFAFA;
}

.icon.edit {
  color: #3689e6;
}

.icon.trash {
  color: #c6262e;
}

.dx-data-row.dx-selection .icon.edit {
  color: white;
}

.dx-data-row.dx-selection .icon.trash {
  color: white;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-500 {
  min-width: 500px;
}

.min-width-800 {
  min-width: 800px;
}

.dx-datagrid-filter-panel {
  background-color: #FAFAFA;
}

.margin-left-1rem {
  margin-left: 1rem !important;
}

.p-btn-devextreme {
  border-radius: .3rem !important;
  /* height: 26px!important;
  padding: 4px 16px!important; */
}

.p-btn-devextreme span {
  text-transform: none !important;

}

.p-btn-devextreme .dx-icon {
  font-size: 1rem !important;

}

.dx-selection .dx-datagrid-group-opened {
  color: white !important;
}

.dx-selection .dx-datagrid-group-closed {
  color: white !important;
}

.ui.large.modal>.header {
  font-size: 1rem !important;
}

.dx-highlight-outline {
  padding: 2px !important;
}

.help-text {
  color: rgba(117, 117, 117, .87);
  font-size: 12px;
}

.float-right {
  float: right;
}

.padding-top-10 {
  padding-top: 10px
}

.margin-left-0 {
  margin-left: 0px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.modal_hinhthuc_content {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.group-checkbox {
  line-height: unset !important;
}

.text-align-right {
  text-align: right !important;
}

.no-padding {
  padding: unset !important;
  padding-bottom: 0px !important;
}

.dx-selection.dx-row>td a {
  color: white !important;
}

.circular-progressbar-height-60 {
  height: 60px;
}

.CircularProgressbar-path {
  stroke: #3caa62 !important;
}

.CircularProgressbar-text {
  fill: #3caa62 !important;
  ;
}

.dx-tagbox.dx-editor-filled .dx-tag-container,
.dx-tagbox.dx-editor-outlined .dx-tag-container {
  padding: 2px 8px 7px;
  min-height: 32px !important;
  height: 32px;
}

.dx-tag-content {
  padding: 3px 32px 3px 8px !important;
}

.ui.fullscreen.scrolling.modal {
  position: relative !important;
}

.dx-checkbox-indeterminate .dx-checkbox-icon {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(0, 0, 0, .54) !important;
  border-radius: 2px;
  background-color: white !important;
}

.dx-checkbox-indeterminate .dx-checkbox-icon::before {
  content: '' !important;
}

.margin-0 {
  margin: 0px !important;
}

.small-radio {
  padding: 2px 8px;
  color: #000;
}

.small-radio.primary.selected {
  border-radius: 10px;
  background-color: rgba(3, 169, 244, .3);
}

.small-radio.success.selected {
  border-radius: 10px;
  background-color: rgba(139, 195, 74, .3)
}

.small-radio.danger.selected {
  border-radius: 10px;
  background-color: rgba(244, 67, 54, .3)
}

.small-radio>input+span {
  height: 15px;
  width: 15px;
}

.p-form-checkbox-cont>input:checked+span {
  background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
}

.p-form-radio-cont>input+span,
.p-form-checkbox-cont>input+span {
  border: 1px solid #32CD32;
}

.text-green {
  color: #3caa62;
}

.p-form-checkbox-cont {
  cursor: pointer;
}

.p-form-checkbox-cont.small>input+span {
  width: 15px !important;
  height: 15px;
}

body>div[style^="position: absolute; inset: 0px auto auto 0px;"] {
  z-index: 3;
}

code.code {
  padding: 1px 5px;
}

.code {
  background: #262526;
  color: #f3f3f3;
  border-radius: 5px;
}

.code-success {
  background: #3caa62 !important;
  color: #f3f3f3;
  border-radius: 5px;
}

.code-warning {
  background: #f2711c !important;
  color: #f3f3f3;
  border-radius: 5px;
}

.dx-selection .text-info {
  color: #f3f3f3;
}

.dx-selection .text-danger {
  color: #f3f3f3;
}

.dx-selection .text-success {
  color: #f3f3f3;
}

.text-danger {
  color: #c6262e;
}

.text-success {
  color: #3caa62;
}

.text-info {
  color: #3689e6;
}

.font-mono {
  /* font-family: "IBM Plex Mono"; */
  /* font-weight: 600; */
}

.PrimaryDropDownButton {
  background: linear-gradient(to bottom, #32CD32 0%, #3caa62 100%);
  box-shadow: inset 0 1px 1px rgb(255 255 255 / 41%), 0px 2px 3px -2px rgb(0 0 0 / 30%);
  border-radius: .3rem;
  height: 26.38px !important;
}

.PrimaryDropDownButton .dx-list-item-icon {
  color: black !important;
}

.PrimaryDropDownButton .dx-button-content {
  color: white !important;
}

.PrimaryDropDownButton .fas {
  color: white !important;
}

.PrimaryDropDownButton .dx-icon-spindown {
  color: white !important;
}

.PrimaryDropDownButton .dx-button-text {
  text-transform: unset !important;
  font-family: -apple-system, "Inter", sans-serif !important;
  font-size: 1rem;
  line-height: unset;
}

.PrimaryDropDownButton .dx-button-content {
  padding: .3rem 1.1rem !important;
}

.PrimaryDropDownButton .dx-buttongroup-item {
  border: 0;
}


.form-group-inline {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #cacaca;
  padding-top: 2px;
  padding-bottom: 2px;
}

.form-group-inline input {
  font-weight: bold;
}

.form-group-inline .form-group-label {
  display: flex !important;
  align-items: center;
  /* font-weight: bold; */
}

.form-group-inline .form-group-input {
  flex-grow: 1;
  margin-left: 1rem;
  border: none !important;
}

.form-group-inline .dx-texteditor {
  flex-grow: 1;
  margin-left: 1rem;
  border: none !important;
}

.form-group-inline .form-group-input::after {
  border-bottom: none !important;
}

.form-group-inline .form-group-input.dx-state-focused::before {
  border-bottom: 0px !important;
}

.form-group-inline .dx-texteditor.dx-state-focused::before {
  border-bottom: 0px !important;
}

.form-group-inline .dx-texteditor.dx-editor-underlined.dx-state-hover::after {
  border-bottom: 0px !important;
}

.form-group-inline .dx-texteditor.dx-editor-underlined::after {
  border-bottom: 0px !important;
}

p.font-mono {
  margin-bottom: 0px;
}